import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import moment from "moment";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, Table, 
  Input, Badge,
  DropdownMenu, DropdownItem,
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, INVENTORY_SOURCES} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactToPrint from 'react-to-print';

import FillModal from "./FinishedPackages/FillModal";
import AddFinishedProductModal from "./FinishedPackages/AddFinishedProductModal";
import PrintPackageBarcodes from "./Packages/PrintPackageBarcodes";
import AdjustInventoryModal from "./Packages/AdjustInventoryModal";
import MoveToWarehouseModal from "./Packages/MoveToWarehouseModal";
import ViewPackageDetailModal from "./Packages/ViewPackageDetailModal";
import MoveToWasteModal from "./FinishedPackages/MoveToWasteModal";


var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

const qty_types = {
				'0' : 'mg', 
				'1' : 'g', 
				'2' : 'oz', 
				'3' : 'units', 
				'4' : 'mg', 
				'5' : 'mg', 
				'6' : 'mg', 
				'7' : 'mg', 
				'8' : '',
				'9' : 'units'
			};

export default class FinishedPackages extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		
		this.state = {
			selectedIds: [],
			selectedTotalQtys: 0,
			selectAll: 0,
			modalOpenFill: false,
			modalOpenAddFinishedProduct: false,
			modalOpenViewPackageDetail: false,
			modalOpenAdjustInventory: false,
			modalOpenMoveToWarehouse: false,
			modalOpenMoveToWaste: false,
			product_package:null,
			product_package_id:0,
			search:"",
			print_packages:[]
		};

		this.toggleModalFill = this.toggleModalFill.bind(this);
		this.toggleModalAddFinishedProduct = this.toggleModalAddFinishedProduct.bind(this);
		this.toggleModalAdjustInventory = this.toggleModalAdjustInventory.bind(this);
		this.toggleModalMoveToWarehouse = this.toggleModalMoveToWarehouse.bind(this);
		this.toggleModalMoveToWaste = this.toggleModalMoveToWaste.bind(this);
	
		this.printBarcodes = this.printBarcodes.bind(this);
		this.activeItem = this.activeItem.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
		var selectedTotalQtys = 0
        if (this.state.selectAll === 0) {
            this.props.finished_products.forEach(x => {
                newSelected.push(x.package_id);
            });
			selectedTotalQtys = this.props.finished_products.filter(r=>{ return newSelected.indexOf(r.package_id)!=-1 }).map(r=>parseInt(r.quantity)).reduce((total,r)=>{ return total+r });
        }
		
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
			selectedTotalQtys
        });
    }
	
	activeItem=(e,package_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(package_id);			
		}else{
			var x=selectedIds.indexOf(package_id);
			if(x>=0){
				selectedIds.splice(x,1);
			}
		}
		if(selectedIds.length!=0 && selectedIds.length==this.props.finished_products.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		
		var selectedTotalQtys = 0;
		if(selectedIds.length!=0){
			selectedTotalQtys = this.props.finished_products.filter(r=>{ return selectedIds.indexOf(r.package_id)!=-1 }).map(r=>parseInt(r.quantity)).reduce((total,r)=>{ return total+r });
		}
		
		
		this.setState({selectedIds,selectAll,selectedTotalQtys});
	}
	
	toggleModalFill = () => {
		this.setState({
			modalOpenFill: !this.state.modalOpenFill
		});
	};
	
	toggleModalAddFinishedProduct = () => {
		this.setState({
			modalOpenAddFinishedProduct: !this.state.modalOpenAddFinishedProduct
		});
	};
	
	toggleModalViewPackageDetail = (e,product_package_id=0) => {
		this.setState({
			product_package_id: product_package_id,
			modalOpenViewPackageDetail: !this.state.modalOpenViewPackageDetail,
		});
	};
	
	toggleModalAdjustInventory = (e,product_package=null) => {
		this.setState({
			product_package: product_package,
			modalOpenAdjustInventory: !this.state.modalOpenAdjustInventory,
		});
	};
	
	toggleModalMoveToWarehouse = (e,product_package=null) => {
		this.setState({
			product_package: product_package,
			modalOpenMoveToWarehouse: !this.state.modalOpenMoveToWarehouse,
		});
	};
	
	toggleModalMoveToWaste = (e) => {
		var selectedIds = this.state.selectedIds;  
		if(!this.state.modalOpenMoveToWaste){			      
			if(selectedIds.length<1){
				swal("Warning","Please select atlest one package","warning");
				return;
			}
		}else{
			selectedIds=[]
		}
		this.setState({
			modalOpenMoveToWaste: !this.state.modalOpenMoveToWaste,
			selectedIds
		});
	};
	
	printBarcodes = (e,package_ids=null)  => {
		var selectedIds = package_ids!=null ? package_ids:this.state.selectedIds;        
		if(selectedIds.length==0){
			swal("Warning","Please select atlest one packages","warning");
			return;
		}else{
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('package_ids',selectedIds.join(','));
			
			axios.post(REST_API_END_POINT+'inventory/get-print-barcode-packages',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var print_packages = data.packages;
					print_packages = print_packages.map(row=>{
						row.quantity=row.product_qty;
						return row;
					})
										
					this.setState({ print_packages });
					setTimeout(function(){ document.getElementById('print-finished-barcodes-hidden').click() }, 1000)
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
  
  
	render() {
		var data = [];
		if(typeof this.props.finished_products!='undefined'){
			data = JSON.parse(JSON.stringify(this.props.finished_products));
			data = data.map(row=>{
				row.capacity_text = parseFloat(row['capacity']).toFixed(2);
				if(row['variation_type']==2){	
					if(row['capacity']==0.125){
						row.capacity_text = '1/8';
					}else if(row['capacity']==0.25){
						row.capacity_text = '1/4';
					}else if(row['capacity']==0.5){
						row.capacity_text = '1/2';
					}else{
						row.capacity_text=row['capacity'];
					}
				}
				
				if(row.source_barcode==null){
					row.source_barcode="";
				}
				if(row.source=="6"){
					row.source_barcode="Kitchen";
				}
				
				row.qty_type_name=qty_types[row['variation_type']];
				row.moved_to_warehouse = parseFloat(row.quantity-row.remainig_to_fill).toFixed(2);
				return row;
			});
		}
		
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search) || row.variation_name.toLowerCase().includes(this.state.search) || INVENTORY_SOURCES[row.source].toLowerCase().includes(this.state.search)
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "package_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"package_id_"+rowInfo.original.package_id} onChange={event =>this.activeItem(event,rowInfo.original.package_id) } checked={this.state.selectedIds.includes(rowInfo.original.package_id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				width:170,
				Cell: props => <p className="list-item-heading" onClick={e=>{ this.toggleModalViewPackageDetail(e,props.original.package_id) }}><span className="btn-link cursor-pointer">{props.value}</span> {moment(parseInt(props.original.timestamp)*1000).format('YYYY-MM-DD')===moment().format('YYYY-MM-DD') ? <Badge color="success">New</Badge>:""}</p>
			},
			{
				Header: "Name",
				accessor: "variation_name",
				Cell: props => <p className="list-item-heading">{props.value+' ('+props.original.capacity_text+' '+props.original.qty_type_name+')'}</p>
			},
			{
				Header: "Quantity",
				accessor: "quantity",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Moved to Warehouse",
				accessor: "moved_to_warehouse",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Source",
				accessor: "source_barcode",
				Cell: props => <p className="list-item-heading">{props.value!="" ? props.value:(props.original.source=='0' ?  'Inventory':INVENTORY_SOURCES[props.original.source])}</p>
			},
			{
				Header: "Filed Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(parseInt(props.value)*1000).format('MM-DD-YYYY')}</p>
			},
			{
				Header: "Time In Room",
				accessor: "time_in_room",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Options",
				accessor: "package_id",
				width:70,
				sortable: false,
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
									<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalViewPackageDetail(e,props.value) }} >
										<span className="d-inline-block" >View Detail</span>				
									</DropdownItem>
									<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalAdjustInventory(e,props.original) }} >
										<span className="d-inline-block" >Adjust Inventory</span>				
									</DropdownItem>
									{props.original.remainig_to_fill>0 ? <DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalMoveToWarehouse(e,props.original) }} >
										<span className="d-inline-block" >Move to Warehouse</span>
									</DropdownItem>:""}
								</DropdownMenu>}
							/>
						</div>
					</Fragment>
			}
	];

	var product_type=1;
	if(this.props.product!=null){
		product_type=parseInt(this.props.product.product_type);
	}
    return( 
		<Fragment>
			<div id="api_message_finished_products"></div>
			<div className="float-sm-right">
				{" "}{this.props.product_variations.length && product_type==1 ? <Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalFill}
					>
					Fill
				</Button>:""}
				{this.props.product_variations.length && product_type==2 ? <Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalAddFinishedProduct}
					>
					Add Finished Product
				</Button>:""}
				{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.toggleSelectAll}
				  >
					Select All
				</Button>{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.printBarcodes}
				  >					
					Print Barcodes
				</Button>				
				{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.toggleModalMoveToWaste}
				  >
					Move to Waste
				</Button>
			</div>
			<div className="mb-4 dropdown-edit small-padd-table">	
				<FormGroup row>
					<Colxx lg="6">
						<Input
							type="text"
							name="finished_products_table_search"
							id="finished_products_table_search"
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({search: e.target.value.toLowerCase()})}
						/>
					</Colxx>
				</FormGroup>
				<ReactTable
					data={data}
					TbodyComponent={CustomTbodyComponent}
					columns={dataTableColumns}
					defaultPageSize={10}
					minRows = {2}
					filterable={false}
					showPageJump={false}
					PaginationComponent={DataTablePagination}
					showPageSizeOptions={true}
					defaultSorted={[{id: "package_id",desc: true}]}
				/>
			</div>
			
			{this.state.modalOpenFill && this.props.product!=null && this.props.product_variations.length ? <FillModal
				modalOpen={this.state.modalOpenFill}
				toggleModal={this.toggleModalFill}
				product={this.props.product}
				unused_packages={this.props.unused_packages}
				product_variations={this.props.product_variations}
				facilities={this.props.facilities}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.props.product_variations.length && product_type==2 ? <AddFinishedProductModal
				modalOpen={this.state.modalOpenAddFinishedProduct}
				toggleModal={this.toggleModalAddFinishedProduct}
				product={this.props.product}
				product_variations={this.props.product_variations}
				facilities={this.props.facilities}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.print_packages.length ? <div className={"d-none"}>
				<ReactToPrint
					trigger={() => <span className="d-block cursor-pointer" id="print-finished-barcodes-hidden" >Print Barcodes</span>}
					content={() => this.componentRef}
					copyStyles={true}
					pageStyle={""}
				/>
				<PrintPackageBarcodes 
					ref={el => (this.componentRef = el)} 
					product_packages={this.state.print_packages} 
					product={this.props.product}
					facility={this.props.facility}					
					selectedIds={this.state.selectedIds} 
				/>
			</div>:""}
			
			{this.props.product!=null && this.state.product_package!=null ? <AdjustInventoryModal
				modalOpen={this.state.modalOpenAdjustInventory}
				toggleModal={this.toggleModalAdjustInventory}
				product={this.props.product}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
				api_message={"api_message_finished_products"}
			/>:""}
			
			{this.props.product!=null && this.state.product_package_id!=0 ? <ViewPackageDetailModal
				modalOpen={this.state.modalOpenViewPackageDetail}
				toggleModal={this.toggleModalViewPackageDetail}
				printBarcodes={this.printBarcodes}
				product={this.props.product}
				product_package_id={this.state.product_package_id}
				is_finished={true}
			/>:""}

			{this.props.product!=null && this.state.product_package!=null ? <MoveToWarehouseModal
				modalOpen={this.state.modalOpenMoveToWarehouse}
				toggleModal={this.toggleModalMoveToWarehouse}
				product={this.props.product}
				warehouses={this.props.warehouses}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
				api_message={"api_message_finished_products"}
			/>:""}			
			
			{this.props.product!=null && this.state.modalOpenMoveToWaste ? <MoveToWasteModal
				modalOpen={this.state.modalOpenMoveToWaste}
				toggleModal={this.toggleModalMoveToWaste}
				product={this.props.product}
				selectedIds={this.state.selectedIds}
				selectedTotalQtys={this.state.selectedTotalQtys}
				dataListRender={this.props.dataListRender}
			/>:""}
			
		</Fragment>
    );
  }
  
};
