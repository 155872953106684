const harvest = [
{
    id: 1,
    barcode: "5592420146130798", 
	strain: "Grapefruit OG",   
	phase: "Flowering",   
	birthDate: "2019-08-22 03:01:00",   
	timeInRoom: "1 Minute",   
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
     barcode: "5592420146130798", 
	strain: "Grapefruit OG",   
	phase: "Flowering",   
	birthDate: "2019-08-22 03:01:00",   
	timeInRoom: "1 Minute",   
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default harvest
