import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectDuration = [
	{ label: "6 Weeks", value: "6", key: 0 },
	{ label: "7 Weeks", value: "7", key: 1 },
	{ label: "8 Weeks", value: "8", key: 2 },
	{ label: "9 Weeks", value: "9", key: 3 },
	{ label: "10 Weeks", value: "10", key: 4 },
	{ label: "11 Weeks", value: "11", key: 5 },
	{ label: "12 Weeks", value: "12", key: 6 },
	{ label: "13 Weeks", value: "13", key: 7 },
	{ label: "14 Weeks", value: "14", key: 8 },
	{ label: "15 Weeks", value: "15", key: 9 },
	{ label: "16 Weeks", value: "16", key: 10 },
];

class AddStrainCategoryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
			typeSelect:null,
			vendor_val:null,
			selectVendors:[],
			selectStrainTypes:[],
			harvestSelect:null,
		};
	
	}
	
	componentDidMount() {		
		var vendors=[];
		var strain_types=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-all-vendors-and-strain-types',formData).then((res)=>{
			var data = res.data;
		
			vendors=data.vendors;
			strain_types=data.strain_types;
			
			vendors = vendors.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			strain_types = strain_types.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectVendors:vendors,
				selectStrainTypes:strain_types
			});		
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {			
			document.getElementById("add-strain").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("straincategoryForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'inventory/add-strain',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-strain").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-strain").disabled=false;
			});

		}
	}
	
	render() {	
		var { selectVendors,selectStrainTypes } = this.state;
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}		  
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Create Strain Category			
				</ModalHeader>
				<AvForm
					id="straincategoryForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Name<span className="text-danger">*</span></Label>
								  <AvField
									id="cname"
									name="name"
									type="text"
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>							
						</Row>
						<Row className="mt-3">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Type</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="strain_type"
										id="strain_type"
										placeholder="--Select Type--"
										value={this.state.typeSelect}						
										options={selectStrainTypes}
										onChange={typeSelect => { this.setState({ typeSelect }) }}
									/>
								</AvGroup>
							</Colxx>	
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Vendor it came from</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="vendor_id"
										id="vendor"
										placeholder="--Select Vendor--"
										value={this.state.vendor_val}						
										options={selectVendors}
										onChange={vendor_val => { this.setState({ vendor_val }) }}
									/>
								</AvGroup>
							</Colxx>						
						</Row>
						<Row className="mt-3">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Harvest Duration</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="harvest_duration"
										id="harvest_duration"
										placeholder="--No of.Weeks--"					
										value={this.state.harvestSelect}						
										options={selectDuration}
										onChange={harvestSelect => { this.setState({ harvestSelect }) }}
									/>
								</AvGroup>
							</Colxx>										
						</Row>
						<Row className="mt-3">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Aromas</Label>
									<AvField
										id="aromas"
										name="aromas"
										type="text"
										autoComplete="off"
									/>
								</AvGroup>
							</Colxx>	
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Flavors</Label>
									<AvField
										id="flavors"
										name="flavors"
										type="text"
										autoComplete="off"
									/>
								</AvGroup>
							</Colxx>				
						</Row>
						<Row className="mt-3">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Effects</Label>
									<AvField
										id="effects"
										name="effects"
										type="text"
										autoComplete="off"
									/>
								</AvGroup>
							</Colxx>	
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Symptoms</Label>
									<AvField
										id="symptoms"
										name="symptoms"
										type="text"
										autoComplete="off"
									/>
								</AvGroup>
							</Colxx>				
						</Row>
						<Row className="mt-3">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>THC</Label>
									<AvField
										id="thc"
										name="thc"
										type="number"
										autoComplete="off"
										min='0'							
									/>
								</AvGroup>
							</Colxx>	
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>CBD</Label>
									<AvField
										id="cbd"
										name="cbd"
										type="number"
										autoComplete="off"
										min='0'														
									/>
								</AvGroup>
							</Colxx>				
						</Row>
						<Row className="mt-3">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Pests</Label>
									<AvField
										id="pests"
										name="pests"
										type="number"
										autoComplete="off"
										min='0'														
									/>
								</AvGroup>
							</Colxx>	
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Micro</Label>
									<AvField
										id="micro"
										name="micro"
										type="number"
										autoComplete="off"
										min='0'														
									/>
								</AvGroup>
							</Colxx>					
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-strain" >
							<IntlMessages id="customer.save" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default AddStrainCategoryModal;
